import { getUserStorable } from '@/helpers/utils'

export default {
    TOGGLE_LOADER(state, loader) {
        state.layout.loader = loader;
    },
    LOGOUT_USER(state) {
        localStorage.removeItem(state.localUserKey);
        window.location.replace(`${state.domain}/logout`)
    },
    SET_AUTH_TOKEN(state, data){
        const expirationTime = new Date( new Date().getTime() + +data.expiresIn * 1000);
        const config = {
            token: data.accessToken,
            tokenExpirationDate: expirationTime.toISOString(),
            user: getUserStorable(data.user),
        }
        state.activeUser = {...data.user};
        const encryptedValue = btoa(escape(JSON.stringify(config)));
        localStorage.setItem(state.localUserKey, encryptedValue);
    },
    UPDATE_USER_INFO(state, user) {
        if(!user.id){return};
        state.activeUser = {...user};
        const get_localUser = localStorage.getItem(state.localUserKey);
        const storedData = JSON.parse(unescape(atob(get_localUser)));
        storedData.user = getUserStorable(user);
        localStorage.setItem(state.localUserKey, btoa(escape(JSON.stringify(storedData))));
    },
}
