
import axios from "@/plugins/axios.js"

const actions = {
  fetchNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/notifications")
        .then((response) => {
          if(response.data.success){
              commit('SET_NOTIFICATIONS', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  markNotifAsRead({ commit }, notifId) {
    return new Promise((resolve, reject) => {
      axios.get(`/notifications/${notifId}/markasread`)
        .then((response) => {
          if(response.data.success){
            commit('MARK_AS_READ', notifId)
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

const getters = {}

const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications
  },
  ADD_NOTIFICATION(state, notification) {
      state.notifications.unshift(notification)
  },
  REMOVE_NOTIFICATION(state, notifId) {
      const ItemIndex = state.notifications.findIndex((item) => item.id == notifId)
      state.notifications.splice(ItemIndex, 1)
  },
  MARK_AS_READ(state, notifId){
    state.notifications.map((item)=>{
      if(item.id == notifId){
          return item.is_seen = 1
      }
    })
  }
}

const state = {
    notifications: [],
}


export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

